// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Row from 'antd/lib/row'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'

import Col from 'antd/lib/col'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'
import Copy from '../components/Copy'
import Video from '../components/Video'
import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Affiliations & Accreditations',
  nakedPageSlug: 'affiliations',
  pageAbstract:
    'Cambridge Innovative School is affiliated with CBSE, which is the largest educational board in the country. We are committed towards a holistic education which aims to instil appropriate and desirable ethical values to make students productive and responsible members of society.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
    color: '#004195',
  },

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = () => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <h1 className="mask-h3">Affiliations & Accreditations</h1>
    <Row gutter={{ xs: 24, sm: 24, md: 24 }}>
      <Col sm={24} md={16}>
        <Copy>
          <p>
            Cambridge Innovative School is affiliated with CBSE,
            which is the largest educational board in the country. We are
            committed towards a holistic education which aims to instil
            appropriate and desirable ethical values to make students productive
            and responsible members of society. We provide student-centered
            education of the highest quality in a creative and cooperative
            environment.
          </p>
          <p>
            The school is affiliated to the Central Board of Secondary
            Education, New Delhi Vide Affiliation No <strong>1630441</strong>{' '}
            under its 10+2 Pattern. A three year Pre-Primary stint is followed
            by five years of Primary Level and three years of Middle Level.
            After devoting two years to Secondary Education the students branch
            off to various streams for the Senior Secondary Level and at the end
            appear for a Board Examination under the aegis of CBSE, New Delhi.
            The streams available in the school for Senior Secondary Level are
            as follows:
          </p>
          <ul className="mask-p">
            <li>
              Medical Stream: English Core, Physics, Chemistry, Biology and
              Physical Education/ Painting/ Music-Vocal or Instrumental/
              Dance/Computer Science/Multimedia & Web Technology.
            </li>
            <li>
              Non-Medical Stream: English Core, Physics, Chemistry, Mathematics
              and Physical Education/ Painting/ Music-Vocal or Instrumental/
              Dance/Computer Science/ Multimedia & Web Technology.
            </li>
            <li>
              Commerce Stream: English Core, Accountancy, Business Studies,
              Economics& Mathematics/ Physical Education/ Painting/ Music-Vocal
              or Instrumental/ Dance/Computer Science/ Multimedia & Web
              Technology
            </li>
            <li>
              Humanities Stream: English Core, History, Political Science,
              Psychology and Physical Education/ Painting/ Music-Vocal or
              Instrumental/ Dance/Computer Science/Multimedia & Web Technology/
              Economics/ Home Science.
            </li>
          </ul>
        </Copy>
      </Col>
      <Col sm={24} md={8}>
        <h4>Quick Stories…</h4>
        <Video
          url="https://www.youtube.com/watch?v=ga2tojHvhVM"
          className="mask-p"
        />
        <p>
          Cambridge community welcomed 63 delegates and
          their team leaders from Wolverhampton University, U.K . They are with
          us for a week and each day staff will be gaining a lot by interacting
          with the team and learning new teaching methods. It is going to be a
          productive knowledge sharing experience where the teaching fraternity
          of Cambridge will exchange ideas with the delegates from England.
        </p>
      </Col>
    </Row>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
